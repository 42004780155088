import {useFlag} from '@/hooks/useFlag';
import {FLAG_BROCHURE_RETAIL_CARD_READER_DISCOUNT} from '@/flags';
import {useSiteData} from '@/hooks/useSiteData';

export const useCardReaderDiscountIncentive = () => {
  const cardReaderDiscountFlag = useFlag(
    FLAG_BROCHURE_RETAIL_CARD_READER_DISCOUNT,
  );
  const {realCountryCode} = useSiteData();

  const isUS = realCountryCode === 'US';

  return cardReaderDiscountFlag && isUS;
};
